import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleSecurityService {

  constructor(private http: HttpClient) { }
  
  getLeftPanelData(username):Observable<any>  {
    return this.http.get<any>(`${environment.apiUrl}/get_left_panel_data/`,{
      params: {
        username
    }});
  }

  getLeftPanelParentData(username){
    return this.http.get<any>(`${environment.apiUrl}/get_left_panel_parent_data/`,{
      params: {
        username
    }});
  }

  getLeftPanelChildData(username,parent_code){
    return this.http.get<any>(`${environment.apiUrl}/get_left_panel_child_data/`,{
      params: {
        username,
        parent_code
    }});
  }

  getLeftPanelSubChildData(username,parent_code,child_code){
    return this.http.get<any>(`${environment.apiUrl}/get_left_panel_sub_child_data/`,{
      params: {
        username,
        parent_code,
        child_code
    }});
  }

}
