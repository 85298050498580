import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from 'src/app/core/service/auth.service';
import { RoleSecurityService } from 'src/app/core/service/role-security.service';

declare const Waves: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  headerHeight = 60;
  routerObj = null;
  screenToRole:any;
  leftpanelparentdata:any;
  leftpanelchilddata:any;
  leftpanelsubchilddata:any;
  role_ref_id: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private roleSecurityService: RoleSecurityService
  ) {
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const currenturl = event.url.split('?')[0];
        this.level1Menu = currenturl.split('/')[1];
        this.level2Menu = currenturl.split('/')[2];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
    if (this.authService.currentUserValue) {
      this.roleSecurityService.getLeftPanelData(localStorage.getItem('USER_ID')).subscribe((lpdata: []) => {
        this.leftpanelparentdata=lpdata;
        for(let i=0;i<this.leftpanelparentdata.length;i++){
          ROUTES.push({
            path: '',
            title: this.leftpanelparentdata[i].form_name,
            moduleName: this.leftpanelparentdata[i].module_path,
            parent_code:this.leftpanelparentdata[i].parent_code,
            child_code:this.leftpanelparentdata[i].child_code,
            icon: this.leftpanelparentdata[i].icon_class,
            class: 'menu-toggle',
            groupTitle: false,
            sequenceId: this.leftpanelparentdata[i].sequence_id,
            submenu: []
          })
          this.leftpanelchilddata = this.leftpanelparentdata[i].submenu_level1;
          for(let j=0;j<this.leftpanelchilddata.length;j++){
            ROUTES.forEach(x=>{
              if(x.parent_code==this.leftpanelchilddata[j].parent_code){
                if(this.leftpanelchilddata[j].form_link == "")
                {
                  x.submenu.push({
                    path: '/',
                    title: this.leftpanelchilddata[j].form_name,
                    moduleName: this.leftpanelchilddata[j].module_path+this.leftpanelchilddata[j].child_code,
                    parent_code:this.leftpanelchilddata[j].parent_code,
                    child_code:this.leftpanelchilddata[j].child_code,
                    icon: this.leftpanelchilddata[j].icon_class,
                    class: 'ml-sub-menu',
                    groupTitle: false,
                    sequenceId: this.leftpanelchilddata[j].sequence_id,
                    submenu: []
                  });
                }
                else
                {
                  x.submenu.push({
                    path: "/"+this.leftpanelchilddata[j].module_path+"/"+this.leftpanelchilddata[j].form_link,
                    title: this.leftpanelchilddata[j].form_name,
                    moduleName: this.leftpanelchilddata[j].module_path,
                    parent_code:this.leftpanelchilddata[j].parent_code,
                    child_code:this.leftpanelchilddata[j].child_code,
                    icon: this.leftpanelchilddata[j].icon_class,
                    class: 'ml-menu',
                    groupTitle: false,
                    sequenceId: this.leftpanelchilddata[j].sequence_id,
                    submenu: []
                  });
                }
              }
            });
            this.leftpanelsubchilddata = this.leftpanelchilddata[j].submenu_level2;  
            for(let k=0;k<this.leftpanelsubchilddata.length;k++){
              ROUTES.forEach(x=>{
                x.submenu.forEach(y=>{
                  if(y.child_code==this.leftpanelsubchilddata[k].child_code){
                    y.submenu.push({
                      path: '/'+this.leftpanelsubchilddata[k].module_path+'/'+this.leftpanelsubchilddata[k].form_link,
                      title: this.leftpanelsubchilddata[k].form_name,
                      moduleName: this.leftpanelsubchilddata[k].module_path+this.leftpanelsubchilddata[k].child_code,
                      parent_code:this.leftpanelsubchilddata[k].parent_code,
                      child_code:this.leftpanelsubchilddata[k].child_code,
                      icon: '',
                      class: 'ml-sub-sub-menu',
                      groupTitle: false,
                      sequenceId: this.leftpanelsubchilddata[k].sequence_id,
                      submenu: []
                    });
                  }
                });
              });
            }
          }
        }
        for(let i=0;i<ROUTES.length;i++){
          ROUTES[i].submenu.sort((a,b)=>a['sequenceId']>b['sequenceId']?1:a['sequenceId']===b['sequenceId'] ? 0 : -1);
        }
        ROUTES.sort((a,b)=>a['sequenceId']>b['sequenceId']?1:a['sequenceId']===b['sequenceId'] ? 0 : -1);
        this.sidebarItems=ROUTES;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element == 'reconciliation'){
      var company_type = localStorage.getItem('company_type')
      if(company_type == 'Aggregator'){
        window.open("https://billerdev.rhythm.works/recon/");
      }
      return
    }

    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }
  ngOnInit() {
    if (this.authService.currentUserValue) {
      this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');

    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');

    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
}
