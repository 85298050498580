import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'reports',
        loadChildren: () =>
          // import('./common-setup/common-setup.module').then((m) => m.CommonSetupModule),
          import('./biller-reports/biller-reports.module').then((m) => m.BillerReportsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'common-setup',
        loadChildren: () =>
          import('./common-setup/common-setup.module').then((m) => m.CommonSetupModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tax_setup',
        loadChildren: () =>
          import('./finance/finance.module').then((m) => m.FinanceModule),
        canActivate: [AuthGuard]
      },
      // {
      //   path:'define_bbpou',
      //   loadChildren: () => 
      //     import('./define-bbpou/define-bbpou.module').then((m) => m.DefineBBPOUModule),
      //   canActivate: [AuthGuard]
      // },
      {
        path:'onboard-biller',
        loadChildren: () => 
          import('./Onboard-Biller/Onboard-Biller.module').then((m) => m.DefineOnboardBiller),
        canActivate: [AuthGuard]
      },
      {
        path:'define-biller-ou',
        loadChildren: () =>          
          import('./define-biller-ou/define-biller-ou.module').then((m) => m.DefineBillerOuModule),
        canActivate: [AuthGuard]
      },
      {
        path:'manage-security',
        loadChildren: () => 
          import('./manage-security/manage-security.module').then((m) => m.ManageSecurityModule),
        canActivate: [AuthGuard]
      },
      {
        path:'api_hub',
        loadChildren: () => 
          import('./api-hub/api-hub.module').then((m) => m.ApiHubModule ),
        canActivate: [AuthGuard]
      },
      {
        path:'define_charges',
        loadChildren:()=>
        import('./define-charges/define-charges.module').then((m)=>m.DefineChargesModule),
        canActivate:[AuthGuard]
      },
      {
        path:'transactions-aggregator',
        loadChildren:()=>
        import('./transactions-aggregator/transactions-aggregator.module').then((m)=>m.TransactionsAggregatorModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'aggregator-setup',
        loadChildren: () =>         
          import('./aggregator-tsp-setup/aggregator-tsp-setup.module').then((m) => m.AggregatorTspSetupModule),
        canActivate: [AuthGuard]
      },
      
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
