import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.authenticationService.logout();
          location.reload();
        }
        var error = err.error.message || err.statusText;
        var Custom_Exception = err.error
        if(typeof(Custom_Exception) == 'object'){

          var getStr = err.error['IntegrityError']
          if (getStr !== undefined){
            function getSecondPart(str) {
              return str.split('DETAIL:  Key ')[1];
            }
            error = getSecondPart(getStr)
          }
          
          var detail = err.error['detail']
          if (detail !== undefined){            
            error = detail
          }
        }
        return throwError(error);
      })
    );
  }
}
