import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  code_token = null;
  username: string;
  password: string;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getCodeToken(): string {
    return this.code_token; 
  }

  resendCode(){
    return this.http.post(
      `${environment.apiUrl}/auth/code/`,
      { username : this.username, password : this.password}
    )
  }

  get token(): string {
    return localStorage.getItem('token');
  }

  setCodeToken(token){
    this.code_token = token;
  }

  code(username: string, password: string){
    this.username = username;
    this.password = password;
    return this.http.post(
      `${environment.apiUrl}/auth/code/`,
      { username, password}
    )
  }

  ResetPassword(username: string){
    this.username = username;
    return this.http.post(
      `${environment.apiUrl}/auth/reset-password/`,
      { username}
    )
  }

  UpdatePassword(user){
    return this.http.post(`${environment.apiUrl}/auth/update-password/`,user)
  }

  private setSession(authResult) {
    const token = authResult.token;
    const payload = <JWTPayload>jwtDecode(token);
    const expiresAt = moment.unix(payload.exp);
    localStorage.setItem('token', token);
    localStorage.setItem('APPLICATION_ID', payload.APPLICATION_ID);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('SUB_APPLICATION_ID', payload.SUB_APPLICATION_ID);
    localStorage.setItem('USER_ID', payload.ID.toString());
    localStorage.setItem('currentUser', payload.ID.toString());
    localStorage.setItem('currentUserValue', payload.ID.toString());
    localStorage.setItem('COMPANY_ID', payload.COMPANY_ID);
    localStorage.setItem('ROLE_ID', payload.ROLE_REF_ID);
    localStorage.setItem('ROLE_NAME', payload.role);
    localStorage.setItem('COMPANY_TYPE_REF_ID', payload.COMPANY_TYPE_REF_ID);
    localStorage.setItem('USER_NAME', payload.username);
    localStorage.setItem('EMAIL_ID', payload.email.toString());
    localStorage.setItem('company_type', payload.company_type.toString());
  }

  login(code_token: string, code: string) {
    return this.http.post(
      `${environment.apiUrl}/auth/login/`,
      { code_token, code }
    ).pipe(
      tap(response => {
        this.setSession(response);
        this.username = null;
        this.password = null;
      }),
      shareReplay(),
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('APPLICATION_ID');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('SUB_APPLICATION_ID');
    localStorage.removeItem('USER_ID');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserValue');
    localStorage.removeItem('COMPANY_ID');
    localStorage.removeItem('ROLE_ID');
    localStorage.removeItem('ROLE_NAME');
    localStorage.removeItem('COMPANY_TYPE_REF_ID');
    localStorage.removeItem('USER_NAME');
    localStorage.removeItem('EMAIL_ID');
    localStorage.removeItem('company_type');
    localStorage.clear();
    return of({ success: false });
  }

  
  
  refreshToken() {
    if (moment().isBetween(this.getExpiration().subtract(1, 'days'), this.getExpiration())) {
      return this.http.post(
        `${environment.apiUrl}/auth/refresh-token/`,
        { token: this.token }
      ).pipe(
        tap(response => this.setSession(response)),
        shareReplay(),
      ).subscribe();
    }
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);

    return moment(expiresAt);
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getLicenseKey(){
    return this.http.get(`${environment.apiUrl}`.concat('/licensekey/'));
  }

  saveLicenseKey(form){
    return this.http.post(`${environment.apiUrl}`.concat('/licensewrite_key/'), form);
  }
  
  // getUserData(){
  //   return this.http.get<any[]>(`${environment.apiUrl}/user/`)
  // }

}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'JWT '.concat(token))
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  } 
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      this.authService.refreshToken();

      return true;
    } else {
      this.authService.logout();
      this.router.navigate(['']);

      return false;
    }
  }
}

export interface JWTPayload {
  ID: number;
  currentUserValue: number;
  currentUser: number;
  username: string;
  email: string;
  exp: number;
  orig_lat: Date;
  role: string;
  APPLICATION_ID: string;
  SUB_APPLICATION_ID: string;
  COMPANY_ID: string;
  ROLE_REF_ID: string;
  COMPANY_TYPE_REF_ID: string;
  company_type: string;
}
